import React, {Component} from 'react'
import {connect} from 'react-redux';
import styled from 'styled-components'
import {navigate} from 'gatsby';
import { tablet } from '../breakpoints';

import { toggleMenu, fetchNav, invertHeader } from '../actions/website';

class mobileMenu extends Component {

    constructor() {
        super();
        this.state = {
            inverted:false
        }
    }

    componentWillMount() {
        this.setState({
            inverted: this.props.invert
        })
    }


    componentWillReceiveProps(next) {
        console.log(next, 'NEXT')
        this.setState({ inverted: next.inverted })
    }

    toggleMenu() {
        this.props.invertHeader(); 
        this.props.fetchNav();
        this.props.toggleMenu();
    }

    renderNav() {
        return this.props.navigation.map((link,i) => {
            return (
                <Item key={i} onClick={()=>{  this.props.invertHeader(); this.props.toggleMenu(); navigate(link.url) } } role="link">{link.title}</Item>
            )
        })
    }

    render() {
        
        return (
            <Wrapper>
                
                <MobileMenu open={this.props.menu_open}>
                    
                    <Close home={this.props.home} open={this.props.menu_open} version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 161.5 161.5" onClick={ this.toggleMenu.bind(this) }>
                        <rect x="73.7" y="-26.4" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -33.4525 80.7633)"  width="14.1" height="214.3"/>
                        <rect x="-26.4" y="73.7" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -33.4532 80.7601)"  width="214.3" height="14.1"/>
                    </Close>

                    <Nav>
                        {this.renderNav()}
                        
                        <Item><a href="http://clients.theradiodepartment.com" target="_blank">Client Area</a></Item>
                    </Nav>
                </MobileMenu>
            
                <SVGMenu home={this.props.home} invert={this.state.inverted} version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 166.8 86.4" onClick={ this.toggleMenu.bind(this) } >
                    <rect width="166.8" height="11"/>
                    <rect y="37.7" width="166.8" height="11"/>
                    <rect y="75.4" width="166.8" height="11"/>
                </SVGMenu>
               
            </Wrapper>
        )
    }
} 


function mapStateToProps(state){
	return { 
        navigation: state.website.navigation,
        menu_open: state.website.menu_open,
        invert: state.website.header_invert
	}
}

export default connect(mapStateToProps, { toggleMenu, fetchNav, invertHeader })(mobileMenu);

 
/*
|--------------------------------------------------------------------------
| Styles
|--------------------------------------------------------------------------
*/
const Wrapper = styled.div`
    display:inline;
` 

const MobileMenu = styled.section`
    position:fixed; 
    background: rgba(0,0,0,.7);
    height:100%;
    width: 100%;
    right:0;
    top:0;
    z-index:888;
    opacity:0;
    visibility:hidden;
    pointer-events:none;
    transition: opacity .2s .4s ease, visibility .2s .4s linear;



    

    ${({ open }) => open && `
        opacity:1;
        visibility:visible;
        pointer-events:auto;
        transition: opacity .2s ease;

        ul {
            transform:translateX(0%);
            transition: .2s ease;
        }
    `}
`

const Close = styled.svg`
    width:30px;
    fill:#000;
    position:absolute;
    left:105px;
    top:20px;
    z-index:99;
    opacity:0;

    @media only screen and (max-width: ${tablet}) {
        right:20px;
        top:20px;
        width:27px;
        left:auto;
    }

    ${({ open }) => open && `
        opacity:1;
    `}
    
    &:hover {
        fill: #fff;
        text-decoration:underline;
        cursor:pointer;
    }
`

const Nav = styled.ul`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding:0; 
    margin:0;
    width:50%;
    float:left;
    padding:40px;
    height:100%;
    background:#f0e452;
    transform:translateX(-100%);
    transition: .2s ease;

    @media only screen and (max-width: ${tablet}) {
        width:100%;
    }
`

const Item = styled.li`
    display:block;
    color:#fff;
    display:block;
    text-align:center;
    font-size:55px;
    color:#000;
    margin-bottom:15px;
    text-decoration:none;

    @media only screen and (max-width: ${tablet}) {
        font-size:40px;
    }

    &:hover {
        color: #fff;
        text-decoration:none;
        cursor:pointer;
    }

    a {
        color:#000;
        text-decoration:none;
        cursor:pointer;
    }

    a:hover {
        color: #fff;
        text-decoration:none;
        cursor:pointer;
    }

`

const SVGMenu = styled.svg`
    max-width:60px;
    float:left;
    max-width: 40px;
    position:absolute;
    top:25px;
    left:100px;
    cursor:pointer;

    ${({ home }) => home=='false' && `
      top:25px;
  `}

    @media only screen and (max-width: ${tablet}) {
        float:right;
        position:relative; 
        left:auto;

        ${({ home }) => home=='false' && `
            top:13px !important;
            right:10px !important;
        `}

        ${({ home }) => home=='true' && `
            top:-2px !important;
            right:-5px !important;
        `}
    }

    

    ${({ home }) => home=="true"  &&`
        fill: #000;  
    `}

    fill: ${(props) => props.invert ? "#000" : "000"};

    &:hover {
        fill:#fff;
    }

    
`