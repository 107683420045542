import { GET_HEADER_STATE, INVERT_HEADER, SET_AUDIO_FILE, SET_AUDIO_POSITION, GET_AUDIO_POSITION, GET_AUDIO_FILE, AUDIO_STATE, AUDIO_RESUME, AUDIO_START, AUDIO_STOP, TOGGLE_MENU, FETCH_NAV } from '../actions/types';

const INITIAL_STATE = {
	navigation: [],
	menu_open: false,
	audio_state: "STOPPED",
	audio_position: 0,
	audio_file: '',
	header_invert: false
};

export default function(state = INITIAL_STATE, action) {

	switch(action.type) {
		case FETCH_NAV:
			return {...state, navigation: action.payload}
        case TOGGLE_MENU:
			return {...state, menu_open: !state.menu_open };
		case AUDIO_START:
			return {...state, audio_state: action.payload };
		case AUDIO_STOP:
			return {...state, audio_state: action.payload };
		case AUDIO_RESUME:
			return {...state, audio_state: action.payload };
		case AUDIO_STATE:
			return {...state };
		case GET_AUDIO_POSITION:
			return {...state };
		case SET_AUDIO_POSITION:
			return {...state, audio_position: action.payload };
		case SET_AUDIO_FILE:
			return {...state, audio_file: action.payload };
		case GET_AUDIO_FILE:
			return {...state };
		case INVERT_HEADER:
			return {...state, header_invert: !state.header_invert }
		case GET_HEADER_STATE:
			return {...state }
		default:
			return state;
	}

}