export const FETCH_NAV = "FETCH_NAV";
export const TOGGLE_MENU = "TOGGLE_MENU";
export const FETCH_ARTISTS = "FETCH_ARTISTS";
export const SUBSCRIBE_USER = "SUBSCRIBE_USER";
export const AUDIO_START = "AUDIO_START";
export const AUDIO_STOP = "AUDIO_STOP";
export const AUDIO_RESUME = "AUDIO_RESUME";
export const AUDIO_STATE = "AUDIO_STATE";
export const SET_AUDIO_POSITION = "SET_AUDIO_POSITION";
export const GET_AUDIO_POSITION = "GET_AUDIO_POSITION";
export const SET_AUDIO_FILE = "SET_AUDIO_FILE";
export const GET_AUDIO_FILE = "GET_AUDIO_FILE";
export const INVERT_HEADER = "INVERT_HEADER";
export const GET_HEADER_STATE = "GET_HEADER_STATE";