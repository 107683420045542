import React from 'react'
import styled from 'styled-components';

import Subscribe from './subscribe';
import FacebookButton from './buttons/facebook';
import TwitterButton from './buttons/twitter';
import InstagramButton from './buttons/instagram';
import { mobile, tablet, desktop } from '../breakpoints';
import radioLogos from '../images/radio_logos.jpg';
import footerLogoA from '../images/footerlogoa.png';
import footerLogoB from '../images/footerlogob.png';
import footerLogoC from '../images/footerlogoc.png';

const FooterFixed = (props) => (
  <FooterSection className={props.className}>
    

    <Inner>
    <RadioLogos>
      <h2>The Radio Department proudly supports:</h2>
      <div>
        <a href="https://www.audiouk.org.uk//" target="_blank"><img src={footerLogoB} /></a>
        <a href="https://www.radioacademy.org/" target="_blank"><img src={footerLogoC} /></a>
        </div>
    </RadioLogos>
      {/* <Subscribe /> */}
    </Inner>
    
    <Socials>
      <Item><a href="https://www.facebook.com/theradiodepartment/" target="_blank"><FacebookButton/></a></Item>
      <Item><a href="https://twitter.com/radiodepartment" target="_blank"><TwitterButton/></a></Item> 
      <Item><a href="https://www.instagram.com/theradiodepartment/" target="_blank"><InstagramButton/></a></Item> 
      <Item>
        <Legal>
          <li><a href="mailto:info@theradiodepartment.com">info@theradiodepartment.com</a></li>
          <li>T +44 345 056 9848</li>
          <li>Design <a href="http://malonedesign.co.uk">MD Studio.</a> <a href="https://lukefreeman.dev">Web Development.</a></li>
        </Legal>
      </Item>
    </Socials>
  </FooterSection>
)


export default FooterFixed

const FooterSection = styled.footer`
    position:fixed;
    top:0;
    left:0;
    bottom:0;
    height:100vh;
    background:#7de1bd;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width:100%;
    z-index:-1;
    min-height:580px;

    @media only screen and (max-width: ${mobile}) {
      position:relative;
		}

`

const RadioLogos = styled.div`
    margin: 20px;
    width: 100%;
    max-width: 700px;
    padding: 3em;
    text-align: center;
    margin: auto;

    h2 {
      font-size: 2em;
      font-weight: 400;
      margin: 40px 0;
    }

    > div {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    a:last-child { margin-left: -130px }

    img {
      max-width: 50%;
    }
`

const Socials = styled.ul`
  padding:0;
  margin:0;
  display:block;
  position:absolute;
  width:100%;
  max-width:1000px;
  bottom:0;
  padding:40px;

  @media only screen and (max-width: ${tablet}) {
    text-align:center;
    bottom:40px;
  }
`;

const Item = styled.li`
  list-style:none;
  display:inline-block;
  width:30px;
  height:30px;
  margin-right:35px;

  &:last-child {
    display:block;
    width:100%;
    color: #fff;
    margin-top:30px;
    letter-spacing: 0.03em;
    font-size: 0.8em;
    margin-right:0;
  }

  &:nth-child(3) {
    margin-right:0;
  }
`

const Legal = styled.ul`
  display:flex;
  flex-direction: row;
  align-items: center;
  padding:0;
  justify-content: space-between;
  
  @media only screen and (max-width: ${tablet}) {
      display:block;
      text-align:center;
      margin-bottom:40px;
      
  }
  
  li {
    list-style:none;
    color: #000;

    a {
      text-decoration:none;
      color:#000;
      opacity:1;
    }

    @media only screen and (max-width: ${tablet}) {
      margin-bottom:2px;
      font-size:11px !important;
    }

  }

`

const Inner = styled.div`
   display:block;
   color:#000;
   width:100%;
   max-width:1000px;
   position:relative;
`