import React from "react"
import { Provider } from "react-redux"
import { createStore, applyMiddleware } from 'redux';
import reducers from './src/reducers';
import promise from 'redux-promise';
import { composeWithDevTools } from 'redux-devtools-extension';

const createStoreWithMiddleware = composeWithDevTools(applyMiddleware(
	promise
))(createStore);  

export default ({ element }) => <Provider store={createStoreWithMiddleware(reducers)}>{element}</Provider>