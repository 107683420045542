import { SUBSCRIBE_USER } from '../actions/types';

const INITIAL_STATE = {
	user_subscribed: false
};

export default function(state = INITIAL_STATE, action) {

	switch(action.type) {
        case SUBSCRIBE_USER:
        	console.log('subscribing user');
			return {...state, user_subscribed: true };
		default:
			return state;
	}

}