import { GET_HEADER_STATE, INVERT_HEADER, SET_AUDIO_FILE, SET_AUDIO_POSITION, GET_AUDIO_POSITION, GET_AUDIO_FILE, AUDIO_RESUME, AUDIO_STATE, AUDIO_START, AUDIO_STOP, TOGGLE_MENU, FETCH_NAV } from './types';
import Config from '../data/siteConfig'; 

export function toggleMenu() {

	
	//apply fixed class to body ---
	if (!document.body.classList.contains('fixed')) {
		document.documentElement.className += ' fixed';
		document.body.className += 'fixed';
	}else {
		document.body.className = '';
		let classes = document.documentElement.className.replace('fixed','');
		document.documentElement.className = classes;
	}

	return {
		type: TOGGLE_MENU,
		payload: true
    };
    
}

export function fetchNav() {

	return {
		type: FETCH_NAV,
		payload: Config.navigation
	};
	
}

export function startAudio() {

	return {
		type: AUDIO_START,
		payload: "PLAYING"
	};

}


export function stopAudio() {

	return {
		type: AUDIO_STOP,
		payload: "PAUSED"
	};

}

export function resumeAudio(){
	return {
		type: AUDIO_RESUME,
		payload: "RESUME"
	};
}


export function audioState() {
	return {
		type: AUDIO_STATE,
		payload: {}
	};
}

export function setAudioPosition(duration) {
	return {
		type: SET_AUDIO_POSITION,
		payload: duration
	};
}

export function getAudioPosition() {
	return {
		type: GET_AUDIO_POSITION,
		payload: {}
	};
}

export function setAudioFile(file) {
	return {
		type: SET_AUDIO_FILE,
		payload: file
	};	
}

export function getAudioFile() {
	return {
		type: GET_AUDIO_FILE,
		payload: {}
	};	
}

export function invertHeader() {
	return {
		type: INVERT_HEADER,
		payload: true
	}
}

export function getHeaderState() {
	return {
		type: GET_HEADER_STATE,
		payload: {}
	}
}