import { FETCH_ARTISTS } from '../actions/types';

const INITIAL_STATE = {
	datetime: new Date(),
	artists: []
};

export default function(state = INITIAL_STATE, action) {

	switch(action.type) {
        case FETCH_ARTISTS:
        	return {...state, artists: action.payload, datetime: new Date() };
		default:
			return state;
	}

}