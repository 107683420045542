import React, {Component} from 'react'
import {connect} from 'react-redux';
import styled from 'styled-components'
import $ from 'jquery';

import {subscribeUser} from '../actions/user';
import { tablet } from '../breakpoints';

class Subscribe extends Component {

    constructor() {
        super();
        this.state = {
            form_submitted: false
        }
    }

	componentDidMount(){
		
    }
    
    subscribe(e) {
        e.preventDefault();

        let form = this.refs.form;
        let email = this.refs.emailInput.value;
        if(email.value!=="" && email.indexOf("@") > 0){
            this.props.subscribeUser(form);
            this.userSubscribed();
        }else{
            return;
        }
    }

    userSubscribed() {
        this.setState({
            form_submitted: true
        })
    }

	render() {

		return (
			<Form onSubmit={this.subscribe.bind(this)} method="post" ref="form" >
                <Wrapper hidden={this.state.form_submitted}>
                    <input type="hidden" name="u" value="c50b815cb63a18748a4de09cc" />
                    <input type="hidden" name="id" value="25c9e80c38" />
                    
                    <EmailInput autocomplete="off" type="email" name="EMAIL" placeholder="Subscribe" required ref="emailInput" />
                    <SubmitButton >
                        <Arrow xmlns="http://www.w3.org/2000/svg"  version="1.1" id="Capa_1" x="0px" y="0px" width="512px" height="512px" viewBox="0 0 408 408" >
                            <g>
                                <g id="arrow-back">
                                    <path d="M408,178.5H96.9L239.7,35.7L204,0L0,204l204,204l35.7-35.7L96.9,229.5H408V178.5z"/>
                                </g>
                            </g>
                        </Arrow>
                    </SubmitButton>
                </Wrapper>

                <ThankYou show={this.state.form_submitted}>Thank you! You are now on our list.</ThankYou>
    		</Form>
		)

  }

}

function mapStateToProps(state){
	return { 
	
	}
}

export default connect(mapStateToProps, {  subscribeUser })(Subscribe);

 
/*
|--------------------------------------------------------------------------
| Styles
|--------------------------------------------------------------------------
*/
const Form = styled.form`
    width:100%;
    display:block;
    padding:40px;
`

const Wrapper = styled.div`
    display:block;

    ${({ hidden }) => hidden && `
        display:none;
    `}
`

const ThankYou = styled.h2`
    display:none;
    text-align:center;
    font-weight:normal;
    letter-spacing:0.03em;

    ${({ show }) => show && `
        display:block;
    `}
`


const EmailInput = styled.input`
    width:100%;
    background:#7de1bd;
    border:none;
    border-bottom: 2px solid #000;
    outline:none;
    font-size:50px;
    color: #000;
    padding-bottom:10px;

    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #000;
    }
    ::-moz-placeholder { /* Firefox 19+ */
    color: #000;
    }
    :-ms-input-placeholder { /* IE 10+ */
    color: #000;
    }
    :-moz-placeholder { /* Firefox 18- */
    color: #000;
    }

    @media only screen and (max-width: ${tablet}) {
        text-align:left;
      }
`

const SubmitButton = styled.button`
    background: transparent;
    border:none;
    width:50px;
    height:50px;
    float:right;
    position: relative;
    top: -65px;
    cursor:pointer;
    outline:none;
`

const Arrow = styled.svg`
    transform: rotate(180deg);
    fill:#000;
    width:100%;
    height:100%;
`