
const Artists = [
    
    /*
    |--------------------------------------------------------------------------
    | Adam Beyer
    |--------------------------------------------------------------------------
    */
    {
        id: 0,
        name: 'Adam Beyer',
        slug: 'adam-beyer',
        showname: 'Drumcode Live',
        photos: ['adambeyer_0.jpg', 'adambeyer_1.jpg', 'adambeyer_2.jpg', 'adambeyer_3.jpg'],
        biog: `<p>Adam Beyer's Drumcode Live is the most listened to weekly techno broadcast in the world. With an audience of 11 million listeners tuning in from over 50 countries, the show has become the undisputed authority for cutting edge techno enthusiasts worldwide.</p>
        <p>Drumcode Live is a 100% live format featuring the hottest performances from Adam and occasional guests' international touring schedules.</p>
        <p>Every episode showcases the freshest and most exclusive techno and house and is always delivered direct from the decks of the world's coolest clubs, arenas and festivals.</p>`,
        facebook: 'https://www.facebook.com/realadambeyer',
        twitter: 'https://twitter.com/realAdamBeyer',
        instagram: 'https://www.instagram.com/realadambeyer',
        audio: 'http://theradiodepartment.com/files/demos/adambeyer_drumcode.mp3',
        showInCarousel: true
    },

    /*
    |--------------------------------------------------------------------------
    | Armin Van Buuren
    |--------------------------------------------------------------------------
    */
    {
        id: 1,
        name: 'Armin van Buuren',
        slug: 'armin-van-buuren',
        showname: 'A State of Trance',
        photos: ['arminvanbuuren_0.jpg', 'arminvanbuuren_1.jpg', 'arminvanbuuren_2.jpg', 'arminvanbuuren_3.jpg'],
        biog: `<p>The world’s most popular DJ presents the world’s biggest electronic music radio show: A State of Trance is a cult institution - the essential listening experience for lovers of trance and progressive music all over the world.
        </p><p>
        On air since 2001, the show attracts over 30 million weekly listeners in almost 100 different countries. ASOT showcases the coolest and most groundbreaking sounds from the global trance scene.
        </p>`,
        facebook: 'https://www.facebook.com/arminvanbuuren',
        twitter: 'https://twitter.com/arminvanbuuren',
        instagram: 'https://www.instagram.com/arminvanbuuren/',
        audio: 'http://theradiodepartment.com/files/demos/arminvanbuuren_astateoftrance.mp3',
        showInCarousel: true
    },

    /*
    |--------------------------------------------------------------------------
    | Amelie Lens
    |--------------------------------------------------------------------------
    */
    {
        id: 23,
        name: 'Amelie Lens',
        slug: 'amelie-lens',
        showname: '',
        photos: ['amelie_0.jpg', 'amelie_1.jpg', 'amelie_2.jpg'],
        biog: `<p>Amelie Lens introduces her new weekly global radio show, Amelie Lens Presents EXHALE. The one hour segment will be helmed by the Belgian techno star, consisting of hand-picked tracks curated and mixed by Amelie each week for her listeners.</p>
        <p>"I am very excited to host my own radio show and have the opportunity to share my favourite music with you every week. Especially in these times where traveling/touring is not so easy, I'm even more happy to be able to reach you at any place in the world with my music."</p>
        `,
        facebook: 'https://www.facebook.com/amelielensmusic',
        twitter: 'https://twitter.com/AmelieLens',
        instagram: 'https://www.instagram.com/amelie_lens/',
        audio: 'http://theradiodepartment.com/files/demos/amelielens.mp3',
        showInCarousel: false
    },


    /*
    |--------------------------------------------------------------------------
    | Chris Liebing
    |--------------------------------------------------------------------------
    */
    // {
    //     id: 2,
    //     name: 'Chris Liebing',
    //     slug: 'chris-liebing',
    //     showname: 'AM/FM',
    //     photos: ['chrisliebing_0.jpg', 'chrisliebing_1.jpg', 'chrisliebing_2.jpg', 'chrisliebing_3.jpg'],
    //     biog: `<p>AM/FM represents a wide spectrum of Chris Liebing ́s musical facets ranging from deep, atmospheric electronica, underground house, vocals and edits to techno in all its colours and shapes.</p>
    //     <p>Over his acclaimed career as an international DJ, producer and radio host, Chris has been continuously shaping his trademark style in each one of those individual disciplines. His sets are sonically dynamic journeys with elements of improvisation, charged with select productions and an arsenal of unreleased tracks by acclaimed colleagues from the global techno scene.</p>
    //     <p>AM/FM offers listeners extended live recordings from some of the most renowned venues on the planet curated for devotees of cutting edge sounds beyond the realm of the musical mainstream.</p>`,
    //     facebook: 'https://www.facebook.com/chrisliebingofficial/',
    //     twitter: 'https://twitter.com/ChrisLiebing',
    //     instagram: 'https://www.instagram.com/chris_liebing/',
    //     audio: 'http://theradiodepartment.com/files/demos/chrisliebing.mp3',
    //     showInCarousel: true
    // },

    /*
    |--------------------------------------------------------------------------
    | DJ EZ
    |--------------------------------------------------------------------------
    */
    // {
    //     id: 14,
    //     name: 'DJ EZ',
    //     slug: 'dj-ez',
    //     showname: 'NUVOLVE',
    //     photos: ['djez_0.jpg', 'djez_1.jpg', 'djez_2.jpg', 'djez_3'],
    //     biog: `<p>DJ EZ is an icon of the UK’s electronic music scene who is synonymous with UK Garage and Bass. He has a reputation as one of the most creative and technically gifted DJs on the planet for over 2 decades.</p>
    //     <p>From headline tours around the world to legendary performances at festivals from Glastonbury to Coachella - DJ EZ’s sets have wowed millions of fans.</p>
    //     <p>Online - he has garnered a cult following. His Boiler Room performances have exceeded 4 million views. He broke internet records with his legendary 24 hour set for Cancer Research.</p>
    //     <p>He is also no stranger to broadcasting with his first radio show airing in 1994 until his weekly slot on London’s Kiss 100 ended in 2014. After a 6 year radio hiatus - he is back with Nuvolve Radio - which will feature underground anthems from UK Garage, Bass and beyond.</p>`,
    //     facebook: 'https://www.facebook.com/djezofficial/',
    //     twitter: 'https://twitter.com/DJEZOfficial',
    //     instagram: 'https://www.instagram.com/djezofficial/',
    //     audio: 'http://theradiodepartment.com/files/demos/djez.mp3',
    //     showInCarousel: false
    // },

    /*
    |--------------------------------------------------------------------------
    | DJ Paulette
    |--------------------------------------------------------------------------
    */
    {
        id: 21,
        name: 'DJ Paulette',
        slug: 'dj-paulette',
        showname: '',
        photos: ['paulette_0.jpg', 'paulette_1.jpg', 'paulette_2.jpg'],
        biog: `<p>DJ Paulette - our Queen of the Streams from the Hacienda, Glitterbox, Boiler Room and Beatport is serving a high octane blend of the best in house, disco and tech house. Every month she will fan your rave flames with the best uplifting vocals, the tastiest beats and basslines and twist it with some hard hitting, big room pressure. Dancing shoes are mandatory.</p>`,
        facebook: 'https://www.facebook.com/djpaulette',
        twitter: 'https://twitter.com/DJPAULETTE',
        instagram: 'https://www.instagram.com/dj_paulette1/',
        audio: 'http://theradiodepartment.com/files/demos/djpaulette.mp3',
        showInCarousel: false
    },

    /*
    |--------------------------------------------------------------------------
    | Global Residency
    |--------------------------------------------------------------------------
    */
    // {
    //     id: 24,
    //     name: 'Global Residency',
    //     slug: 'global-residency',
    //     photos: ['gr_0.jpg'],
    //     showname: '',
    //     biog: `<p>This is the Global Residency - a weekly series for worldwide radio featuring an ever changing rotation of groundbreaking and iconic artists, bands and DJs.</p>
    //     <p><strong>Season 2 features</strong>
    //     <ul style="padding:0; margin:0;">
    //         <li style="padding:0; margin:0; list-style: none;"><a style="color:#000; text-decoration: none;" href="/artist/dj-paulette">DJ Paulette</a></li>
    //         <li style="padding:0; margin:0; list-style: none;"><a style="color:#000; text-decoration: none;" href="">Elvin Zedo</a></li>
    //         <li style="padding:0; margin:0; list-style: none;"><a style="color:#000; text-decoration: none;" href="/artist/pendulum">Pendulum</a></li>
    //         <li style="padding:0; margin:0; list-style: none;"><a style="color:#000; text-decoration: none;" href="/artist/real-lies">Real Lies</a></li>
    //         <li style="padding:0; margin:0; list-style: none;"><a style="color:#000; text-decoration: none;" href="/artist/shimza">Shimza</a></li>
    //     </ul>
    //     `,
    //     facebook: null,
    //     twitter: null,
    //     instagram: null,
    //     audio: 'http://theradiodepartment.com/files/demos/djpaulette.mp3',
    //     showInCarousel: false
    // },

    /*
    |--------------------------------------------------------------------------
    | Jamie Jones
    |--------------------------------------------------------------------------
    */
    {
        id: 15,
        name: 'Jamie Jones',
        slug: 'jamie-jones',
        showname: 'Hot Robot Radio',
        photos: ['jamiejones_0.jpg','jamiejones_1.jpg','jamiejones_2.jpg'],
        biog: `<p>A globally respected and highly accomplished artist, label boss at the seminal Hot Creations, founder and curator of the worldwide Paradise event series and member of cross-genre band Hot Natured, Jamie Jones has achieved iconic status in the electronic
        music world.</p><p>A trendsetter and seasoned tastemaker, the Welsh dance music superstar pioneered his own distinct sound, drawing on his deep knowledge and experience to cultivate DJ sets that weave through a range of diverse, complementary styles selected to energise and enchant the dance floor.</p>`,
        facebook: 'https://www.facebook.com/jamiejonesmusic',
        twitter: 'https://twitter.com/JamieJonesMusic',
        instagram: 'https://www.instagram.com/jamiejonesmusic/',
        audio: 'http://theradiodepartment.com/files/demos/jamie_jones.mp3',
        showInCarousel: false
    },


    /*
    |--------------------------------------------------------------------------
    | John Digweed
    |--------------------------------------------------------------------------
    */
    {
        id: 5,
        name: 'John Digweed',
        slug: 'john-digweed',
        showname: 'Transitions',
        photos: ['johndigweed_0.jpg','johndigweed_1.jpg','johndigweed_2.jpg','johndigweed_3.jpg'],
        biog: `<p>Transitions is the groundbreaking electronic music show presented by John Digweed - a radio show so successful that it is now broadcast in fifty countries worldwide and has spawned four internationally best selling mix CDs.
        </p><p>
        Featuring exclusive studio and live mixes from John Digweed plus guest mixes from the best electronic DJs and producers - Transitions has a reputation and history as one of the world's most popular specialist radio shows.
        </p>`,
        facebook: 'https://www.facebook.com/djjohndigweed',
        twitter: 'https://twitter.com/DJJohnDigweed',
        instagram: 'https://www.instagram.com/djjohndigweed',
        audio: 'http://theradiodepartment.com/files/demos/johndigweed_transitions_part1.mp3',
        showInCarousel: true
    },

    /*
    |--------------------------------------------------------------------------
    | Joris Voorn
    |--------------------------------------------------------------------------
    */
    {
        id: 6,
        name: 'Joris Voorn',
        slug: 'joris-voorn',
        showname: 'Spectrum Radio',
        photos: ['jorisvoorn_0.jpg','jorisvoorn_1.jpg', 'jorisvoorn_2.jpg', 'jorisvoorn_3.jpg'],
        biog: `<p>Joris Voorn sits at the epicenter of the Dutch electronic music scene, the most renowned and instantly recognisable artist from Holland, representing underground house and techno on a wider global stage.</p>
        <p>His all-encompassing Spectrum project launched in 2017, bringing the world his own branded, specially curated events, that feature extended sets by Joris himself and a stunning photography series that runs alongside the parties — and now, an acclaimed radio show too!</p>`,
        facebook: 'https://www.facebook.com/djjohndigweed',
        twitter: 'https://twitter.com/DJJohnDigweed',
        instagram: 'https://www.instagram.com/jorisvoorn',
        audio: 'http://theradiodepartment.com/files/demos/joris_voorn.mp3',
        showInCarousel: true
    },
    

    /*
    |--------------------------------------------------------------------------
    | Mark Brown
    |--------------------------------------------------------------------------
    */
    // {
    //     id: 7,
    //     name: 'Mark Brown',
    //     slug: 'mark-brown',
    //     photos: ['markbrown_0.jpg','markbrown_1.jpg','markbrown_2.jpg'],
    //     biog: `<p>Now eight years since Mark Brown first took to the airwaves with his Cr2 Live & Direct radio show, it has become a solid fixture on over 70 of the world's best FM and digital radio networks.</p>
    //     <p>The Cr2 Live & Direct show is the place to go for the most upfront, exhilarating house music every single week with over 400 episodes in the archives. The emphasis is quite simply on the best underground dance music, delivered in a punchy weekly one hour format.</p>`,
    //     facebook: 'https://www.facebook.com/markbrowndj',
    //     twitter: 'https://twitter.com/markbrowndj',
    //     instagram: 'https://www.instagram.com/markbrownofficial',
    //     audio: 'http://theradiodepartment.com/files/demos/cr2.mp3',
    //     showInCarousel: true
    // },

     /*
    |--------------------------------------------------------------------------
    | Musical Freedom
    |--------------------------------------------------------------------------
    */
    {
        id: 8,
        name: 'Musical Freedom',
        slug: 'musical-freedom',
        showname: 'Musical Freedom Radio',
        photos: ['musicfreedom_0.jpg'],
        biog: `<p>
        Tiësto founded Musical Freedom in 2009 as a way to freely release exciting music he and his friends were producing and performing in their live sets, making it accessible to a global audience.
        </p><p>
        In Tiësto’s continued passion to discover and mentor emerging talent, Musical Freedom has become one of the most exciting labels in the world known for its new sounds and artists, breaking genres and conventional release strategies.
        </p><p>
        The label has had its finger on the pulse of dance music for a decade. Some of the many examples are the underground staples including Sandro Silva & Quintino’s Epic, Oliver Heldens’ Gecko, KSHMR’s Wildcard, plus Tiësto’s club singles, collaborations and celebrated CLUBLIFE album series.
        </p><p>
        Musical Freedom Radio gives an ever evolving rotation of resident guests - the future radio show hosts and superstar DJs of tomorrow, a platform to play their music to the world.  
        </p><p>
        Hosts have already featured include CID, Kryder, Magnificence, Volac, Vintage Culture and Benny Benassi.
        </p>`,
        facebook: 'https://www.facebook.com/musicalfreedom',
        twitter: 'https://twitter.com/musicalfreedom',
        instagram: 'https://www.instagram.com/musicalfreedom/',
        audio: 'http://theradiodepartment.com/files/demos/musical_freedom.mp3',
        showInCarousel: true
    },

    /*
    |--------------------------------------------------------------------------
    | Nicole Moudaber
    |--------------------------------------------------------------------------
    */
    {
        id: 9,
        name: 'Nicole Moudaber',
        slug: 'nicole-moudaber',
        showname: 'In the MOOD',
        photos: ['nicolemoudaber_0.jpg','nicolemoudaber_1.jpg','nicolemoudaber_2.jpg'],
        biog: `<p>Nicole Moudaber is currently taking the world of electronic music by storm. Originally from Lebanon via London, Ibiza and New York City, she now tours the world's biggest and best clubs and festivals, setting dance floors alight wherever she goes.</p>
        <p>Her radio show In the Mood became an overnight sensation when it launched in 2014 with Nicole fusing her uber cool street style with everything from deep hypnotic house to her trademark uncompromising techno sound. Get In The Mood with the queen of the airwaves.</p>`,
        facebook: 'https://www.facebook.com/officialpage?ref=nf',
        twitter: 'https://twitter.com/nicolemoudaber',
        instagram: 'https://www.instagram.com/nicolemoudaber/',
        audio: 'http://theradiodepartment.com/files/demos/inthemood.mp3',
        showInCarousel: true
    },

    /*
    |--------------------------------------------------------------------------
    | Paul Oakenfold
    |--------------------------------------------------------------------------
    */
    {
        id: 10,
        name: 'Paul Oakenfold',
        slug: 'paul-oakenfold',
        showname: 'Planet Perfecto',
        photos: ['pauloakenfold_0.jpg', 'pauloakenfold_1.jpg', 'pauloakenfold_2.jpg', 'pauloakenfold_3.jpg'],
        biog: `<p>Since pioneering the genre in Ibiza back in 1987, Paul Oakenfold has remained one of the world's leading ambassadors of electronic music.
        </p><p>
        His high profile work with the likes of Madonna, U2 and The Happy Mondays have made him a household name, but it's his legendary DJ sets to many millions of fans across the globe that have cemented his position as one of the most respected names on the scene.
        </p><p>
        Planet Perfecto is a show with an innovative style and image unlike anything heard before, taking in every aspect of Paul Oakenfold's sound - from respected club DJ to Hollywood film composer.
        </p>`,
        facebook: 'https://www.facebook.com/Oakenfold',
        twitter: 'https://twitter.com/pauloakenfold',
        instagram: 'https://www.instagram.com/pauloakenfold/',
        audio: 'http://theradiodepartment.com/files/demos/planetperfecto.mp3',
        showInCarousel: true
    },

    /*
    |--------------------------------------------------------------------------
    | Pendulum
    |--------------------------------------------------------------------------
    */
    // {
    //     id: 30,
    //     name: 'Pendulum',
    //     slug: 'pendulum',
    //     photos: ['pendulum_0.jpg'],
    //     showname: '',
    //     biog: `<p>One of the most iconic electronic acts of all time with 3 platinum selling albums, Pendulum have headlined stages at festivals worldwide including Coachella, Download and Creamfields. They also stunned fans this summer with surprise performances at Reading and Leeds Festival and are preparing for 2 sold out shows at London’s Brixton Academy in March.</p>
    //     <p><a href="/artist/global-residency">Pendulum is part of the Global Residency.</p>`,
    //     facebook: 'https://www.facebook.com/pendulum',
    //     twitter: 'https://twitter.com/Pendulum',
    //     instagram: 'https://www.instagram.com/pendulum/',
    //     audio: 'http://theradiodepartment.com/files/demos/pendulum.mp3',
    //     showInCarousel: true
    // },

    /*
    |--------------------------------------------------------------------------
    | Real Lies
    |--------------------------------------------------------------------------
    */
    // {
    //     id: 22,
    //     name: 'Real Lies',
    //     slug: 'real-lies',
    //     showname: '',
    //     photos: ['reallies_0.jpg'],
    //     biog: `<p>Each episode of UNREAL Radio feels like a transmission from some rainswept medium-wave netherworld. Landing somewhere between a late-night phone-in and pirate radio, the show is an hour-long mix put together by RL producer Patrick King that features original songs and lyrics from vocalist Kevin Lee Kharas. Expect heavy duty cuts and the first blooms of new material, shot through with a heavy dose of city night romance.</p>
    //     <p><a href="/artist/global-residency">Real Lies is part of the Global Residency.</p>`,
    //     facebook: 'https://www.facebook.com/RealLies',
    //     twitter: 'https://twitter.com/_real_lies',
    //     instagram: 'https://www.instagram.com/real_lies_/',
    //     audio: 'http://theradiodepartment.com/files/demos/reallies.mp3',
    //     showInCarousel: false
    // },

      /*
    |--------------------------------------------------------------------------
    | Shimza
    |--------------------------------------------------------------------------
    */
    {
        id: 31,
        name: 'Shimza',
        slug: 'shimza',
        photos: ['shimza_0.jpg'],
        showname: '',
        biog: `<p>Combining his unrelenting passion for music with an inextinguishable desire to make a positive impact on his surroundings, Shimza is a totem figure in South Africa’s music scene and one of the leaders of house music in Africa. Making worldwide waves with releases on the likes of Cadenza Records and Knee Deep In Sound, Shimza launched KUNYE (which means together in Xhosa/Zulu) in November 2020, an imprint that provides a bridge between South Africa’s emerging producers and the global stage. Inviting a selection of artists to perform in KUNYE’s weekly livestream sessions, Shimza formed a new movement for Afro Tech Sounds, while amplifying the label’s mission to unite people through the love of music.</p>`,
        facebook: 'https://www.facebook.com/shimza.dj',
        twitter: 'https://twitter.com/Shimza01',
        instagram: 'https://www.instagram.com/shimza.dj/',
        audio: 'http://theradiodepartment.com/files/demos/shimza.mp3',
        showInCarousel: false
    },

    /*
    |--------------------------------------------------------------------------
    | Spinnin' Sessions
    |--------------------------------------------------------------------------
    */
    {
        id: 12,
        name: 'Spinnin’ Sessions',
        slug: 'spinnin-sessions',
        photos: ['spinnin_0.jpg'],
        biog: `<p>Spinnin' is now undoubtedly the biggest label in dance music today and its highly popular weekly radio show has grown to become a leading tastemaker for the international electronic community.</p>
        <p>Hosted by Greg Jake, the show brings you the most upfront sounds that are currently making waves on the worldwide stage. Exciting, exclusive, often unreleased music - from the most happening artists in the world ranging from deep and tropical house to electro and progressive.</p>
        <p>The second half of the show features guest mixes from the world's biggest stars such as Martin Garrix, Oliver Heldens, Tiesto, Dimitri Vegas & Like Mike, Diplo, Afrojack, W&W and many more.</p>
        <p>Already one of the hottest fixtures on international radio airwaves, this show just gets bigger and bolder with every episode and so we are thrilled to now be able to offer it to you for your weekly broadcasting schedule.</p>`,
        facebook: 'https://www.facebook.com/SpinninSessions',
        twitter: 'https://twitter.com/spinninsessions',
        instagram: 'https://www.instagram.com/spinninsessions/',
        audio: 'http://theradiodepartment.com/files/demos/spinnin_showreel.mp3',
        showInCarousel: false
    },

    /*
    |--------------------------------------------------------------------------
    | Tiesto
    |--------------------------------------------------------------------------
    */
    {
        id: 13,
        name: 'Tiësto',
        slug: 'tiesto',
        showname: 'CLUBLIFE',
        photos: ['tiesto_0.jpg','tiesto_1.jpg','tiesto_2.jpg'],
        biog: `<p>Tiësto is a Grammy® Award-winning, platinum-certified, international icon. The DJ and producer is the only artist to ever hold the titles of “The Greatest DJ of All Time” courtesy of Mixmag, “#1 DJ” according to Rolling Stone, and “The Godfather of EDM” as proclaimed by Billboard. From his high-profile residency at Hakkasan to his crossover success, Tiësto created the blueprint that defines what it means to be a success in today’s dance music world.</p>`,
        facebook: 'https://www.facebook.com/tiesto',
        twitter: 'https://twitter.com/tiesto',
        instagram: 'https://www.instagram.com/tiesto/',
        audio: 'http://theradiodepartment.com/files/demos/tiestoclublife.mp3',
        showInCarousel: true
    },

    /*
    |--------------------------------------------------------------------------
    | Ultra Music Festival
    |--------------------------------------------------------------------------
    */
    {
        id: 14,
        name: 'Ultra Music Festival',
        slug: 'ultra-music-festival',
        photos: ['ultra_0.jpg'],
        showname: 'UMF Radio',
        biog: `<p>Every week UMF Radio offers stations across the globe the opportunity to broadcast explosive live performances from Ultra Music Festival's key headline acts.
        </p><p>
        Presented weekly by Andy Pate, this is the ultimate mix show, featuring the world's most in demand DJs. No genre restrictions - just pioneering performances brought to you direct from the planet's hottest dancefloor.
        </p>`,
        facebook: 'https://www.facebook.com/UMFRADIO',
        twitter: 'https://twitter.com/umfradio',
        instagram: 'https://www.instagram.com/ultra/',
        audio: 'http://theradiodepartment.com/files/demos/umfradio.mp3',
        showInCarousel: false
    }
    
]

module.exports = Artists;