const Config = {
    
    /*
    |--------------------------------------------------------------------------
    | Nav
    |--------------------------------------------------------------------------
    */
    navigation: [
        {title: 'What We Do', url: '/production'},
        {title: 'Current Projects', url: '/syndication'},
        {title: 'Podcasts', url: '/podcasts'},
        {title: 'Live Broadcasts', url: '/live'},
        {title: 'Archive', url: '/archive'},
        {title: 'About Us', url: '/about'},
        
    ]

}

export default Config; 