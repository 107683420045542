import { SUBSCRIBE_USER } from './types';
import $ from 'jquery';

export function subscribeUser(form) {

	var form = $('form');

	console.log(form);

	$.ajax({
		type: 'GET',
		url: 'https://theradiodepartment.us20.list-manage.com/subscribe/post-json?c=?',
		data: form.serialize(),
		cache       : false,
		dataType    : 'json',
		contentType: "application/json; charset=utf-8",
		error       : function(err) { console.log(err) },
		success     : function(data) {
				if (data.result != "success") {
						// Something went wrong, do something to notify the user. maybe alert(data.msg);
				} else {
						// It worked, carry on...
				}
		}
	});

	return {
		type: SUBSCRIBE_USER,
		payload: ['asdads']
    };
    
}
