import React from 'react'
import styled from 'styled-components';

import Subscribe from './subscribe';
import FacebookButton from './buttons/facebook';
import TwitterButton from './buttons/twitter';
import InstagramButton from './buttons/instagram';
import { mobile, tablet, desktop } from '../breakpoints';

const Footer = ({className}) => (
  <FooterSection className={className}>
    <Inner>
    
    </Inner>
   
  </FooterSection>
)


export default Footer

const FooterSection = styled.footer`
    height:100%;
    background:transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width:100%;
    position:relative;
    pointer-events:none;

    

    @media only screen and (max-width: ${mobile}) {
      height:0;
      
		}
`

const Inner = styled.div`
   display:block;
   color:#f9ad8f;
   width:100%;
   max-width:1000px;
`