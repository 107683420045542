import React, {Component} from 'react'
import styled from 'styled-components';
import {Link} from 'gatsby';
import {tablet} from '../breakpoints';

import Logo from './logo';
import MobileMenu from './mobileMenu';

class Header extends Component {

  render() {
    return (
      <TopBar home={this.props.isHome}><Link to="/"><Logo invert={this.props.invert} home={this.props.isHome} /></Link><h1>The Radio Department</h1><MobileMenu inverted={this.props.invert} home={this.props.isHome} /></TopBar>
    )
  }

}


export default Header

const TopBar = styled.header`
  position:fixed;
  top:0;
  left:0;
  width:100%;
  padding:20px;
  z-index:999;

  ${({ home }) => home=='false' && `
      background:#c2b0bc;
      border-bottom:2px solid #000;
      padding:5px;
      height:72px;
  `}

  h1 {
    position: absolute;
    right: 20px;
    top: 11px;
    font-family: EuclidFlexMedium !important;
    font-weight: 400;
    margin-top: 10px;
    font-size:19px;

    ${({ home }) => home=='true' && `
      display:none;
      `}
    
    @media only screen and (max-width: ${tablet}) {
      position:absolute;
      font-size: 15px;
      left: 80px;
      top: 10px;
      display:block;
      
      ${({ home }) => home=='true' && `
        color:#000;
      `}
    }
  }

  @media only screen and (max-width: ${tablet}) {
    height:60px;
    background:#fae7d4;

    ${({ home }) => home=='false' && `
      background:#c2b0bc;
    `} 
  }


  a {
    z-index:999;
    position:relative;
  }
`