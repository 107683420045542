import React, {Component} from 'react';
import styled from 'styled-components';
import lottie from 'lottie-web';
import {tablet} from '../breakpoints';

class Logo extends Component {

    constructor(){
        super();

        this.state = {
            inverted: false
        }
    }

    componentWillMount() {
        this.setState({
            inverted: this.props.invert
        })
    }

    componentDidMount() {
        const element = document.querySelector('#logo');

		lottie.loadAnimation({
			container: element, 
			renderer: 'svg',
			loop: true,
			autoplay: true,
			path: '/rd-logo.json' 
		});
    }

    componentWillReceiveProps(next) {
        this.setState({
            inverted: next.invert
        })
    }

    render() {
        return (
            <RDLogo id="logo" invert={this.state.inverted} home={this.props.home} ></RDLogo>
        )
    }
}

export default Logo;

const RDLogo = styled.span`
    width: 70px;
    height: 70px;
    display: inline-block;
    position: relative;
    top:-20px;
    left:-10px;

    ${({ home }) => home=='false' && `
        top:-5px;
        left:5px;
    `}

    ${({ home }) => home=='true' && `
        top:-20px;
    `}

    @media only screen and (max-width: ${tablet}) {
        ${({ home }) => home=='false' && `
            top:-10px;
            left:5px;
        `}
        
        ${({ home }) => home=='true' && `
            top:-25px;
        `}
    }

    svg {
        @media only screen and (max-width: ${tablet}) {
            width:87% !important;
        }

        path {
            fill: #000;
            stroke: #000;    

            ${({ home }) => home=="true" && `
                fill: #000;
                stroke: #000;   
            `}
        }

        

        
    }

    ${({ invert }) => invert && `
        svg {
            path {
                fill: #000;
                stroke: #000;    
            }
        }
    `}

`