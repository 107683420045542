import React, {Component} from 'react'
import {connect} from 'react-redux';
import { fetchNav, startAudio, stopAudio, audioState, getAudioPosition, setAudioPosition, setAudioFile } from '../actions/website';
import styled from 'styled-components'
import _ from 'lodash';
import {tablet} from '../breakpoints';

import Sound from 'react-sound';
import Header from '../components/header';
import Footer from '../components/footer';
import FooterFixed from '../components/footerFixed';
import Artists from '../data/artists';
import verge from 'verge';

import '../css/global.css';

class Layout extends Component {

    constructor(){
        super();

        this.footerEl = null;

        this.state = {
            headerInvert: false,
            isHome:false
        }
    }

    componentWillMount() {
        
    }

	componentDidMount(){

        this.footerEl = document.querySelector('.footerSpacer');
        window.addEventListener('scroll', this.handleScroll.bind(this));

        window.soundManager.setup({
            debugMode: false,
            onready: ()=> {
                this.loadAudio();
            }
        });

		this.props.fetchNav();
    }

    handleScroll(e){
        const checkPosition = _.debounce(()=>{

            if(verge.inViewport(this.footerEl, (-window.innerHeight+30))){
                this.setState({
                    headerInvert: true
                });
            }

            if(!verge.inViewport(this.footerEl, (-window.innerHeight+80))){
                this.setState({
                    headerInvert: false
                });
            }

        }, 150);

        checkPosition();
    }

    /*
    |--------------------------------------------------------------------------
    | watch for prop updates
    |--------------------------------------------------------------------------
    */
    componentWillReceiveProps(next){

        if(this.props.location.pathname === "/") this.setState({ isHome: true })

        //listen for header invert changes
        if(next.headerInvert !== this.props.headerInvert) this.setState({ headerInvert: next.headerInvert })

        //listen for audio player updates
        if(next.audio_state !== this.props.audio_state && next.audio_state === "PLAYING") this.playAudio(next.audio_file);
        if(next.audio_state !== this.props.audio_state && next.audio_state === "PAUSED") this.pauseAudio(next.audio_file);
        if(next.audio_state !== this.props.audio_state && next.audio_state === "RESUME") this.resumeAudio(next.audio_file);
        if(next.audio_file !== this.props.audio_file) this.playAudio(next.audio_file);
    }

    /*
    |--------------------------------------------------------------------------
    | preload all audio
    |--------------------------------------------------------------------------
    */
    loadAudio(){
        return Artists.map(artist => { 
            if(artist.audio !== ""){
                return window.soundManager.createSound({
                    id: `${artist.slug}`,
                    url: artist.audio,
                    onload: function() {
                       
                    },
                    volume: 100
                });
            }else{
                return null;
            }
        })
    }

    parseAudioFileName(slug) {
        return slug.split('-').join('_');
    }

    /*
    |--------------------------------------------------------------------------
    | stop all audio
    |--------------------------------------------------------------------------
    */ 
    stopAll(){
        for (let key of Object.keys(window.soundManager.sounds)) {
            let sound = window.soundManager.sounds[key];
            if(sound.playState) sound.stop();
        }
    }
   
    /*
    |--------------------------------------------------------------------------
    | play audio
    |--------------------------------------------------------------------------
    */
    playAudio(file) {
        this.stopAll();
        window.soundManager.play(file);
    }

    /*
    |--------------------------------------------------------------------------
    | pause audio
    |--------------------------------------------------------------------------
    */
    pauseAudio(file) {
        window.soundManager.pause(file);
    }

    /*
    |--------------------------------------------------------------------------
    | resume audio
    |--------------------------------------------------------------------------
    */
    resumeAudio(file) {
        window.soundManager.resume(file);
    }

    /*
    |--------------------------------------------------------------------------
    | set current audio state
    |--------------------------------------------------------------------------
    */
	setAudioState() {
		switch(this.props.audio_state) {
			case "STOPPED":
				return Sound.status.PAUSED
			case "PLAYING":
                return Sound.status.PLAYING
            default:
                return Sound.status.PAUSED

		}
	}

    /*
    |--------------------------------------------------------------------------
    | render layout
    |--------------------------------------------------------------------------
    */
	render() {
        const isHome = this.props.location.pathname === "/" ? true : false;

		return (
			<Container isHome={isHome ? 'true' : 'false'}>
                <Header invert={this.state.headerInvert} isHome={isHome ? 'true' : 'false'} />
                {this.props.children}
                <Footer className="footerSpacer" />
                <FooterFixed className="footerFixed" />
    		</Container>
		)
  }

}

function mapStateToProps(state){
	return { 
		audio_state: state.website.audio_state,
        audio_position: state.website.audio_position,
        audio_file: state.website.audio_file,
        headerInvert: state.website.header_invert
	}
}

export default connect(mapStateToProps, { fetchNav, startAudio, stopAudio, audioState, getAudioPosition, setAudioPosition, setAudioFile })(Layout);

 
/*
|--------------------------------------------------------------------------
| Styles
|--------------------------------------------------------------------------
*/
const Container = styled.section`
    height:100%;
    
    ${({ isHome }) => isHome=='false' && `
      padding-top:72px;
    `}

    @media only screen and (max-width: ${tablet}) {
        ${({ isHome }) => isHome=='false' && `
        padding-top:60px;
        `}
    }
`;


