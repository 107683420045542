import { combineReducers } from 'redux';
import ArtistReducer from './reducer_artists';
import WebsiteReducer from './reducer_website';
import UserReducer from './reducer_user';

const rootReducer = combineReducers({
	website: WebsiteReducer,
	user: UserReducer, 
	artists: ArtistReducer 
});

export default rootReducer;